<template>
  <div>
    <div align="center" class="my-5" v-if="carrello_vuoto === ''">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div> 

    <section class="mb-4" v-if="carrello_vuoto === true">
      <b-jumbotron header="Carrello è vuoto" lead="Puoi scegliere gli articoli da ordinare nella sezione Articoli" align="center">
        <b-button variant="primary" :to="{ name: 'catalogo-articoli'}">Articoli</b-button>
      </b-jumbotron>
    </section>    

    <section id="dashboard-ecommerce" class="mb-4" v-if="carrello_vuoto === false">
      <b-alert
        variant="danger"
        show
        v-if="data.totale.subtotale < 300"
      >
        <h4 class="alert-heading">ATTENZIONE!!</h4>
        <div class="alert-body">
          <span>Il tuo ordine non supera i 300 &euro; +iva e quindi saranno addebitate le spese di spedizione direttamente in fattura</span>
        </div>
      </b-alert>

      <b-card
        v-if="data.carrello"
        no-body
        class="card-company-table"
      >
        <b-table
          :items="data.carrello"
          responsive
          :fields="fields"
          class="mb-0"
        >
          <!-- articolo -->
          <template #cell(articolo)="data">
            <span class="font-weight-bolder">
                {{ data.item.descrizione_articolo }}
            </span><br />
            <span class="font-small-2 text-muted">
                codice art.: {{ data.item.codice_articolo }}
            </span>
          </template>

          <!-- prezzo_cadauno -->
          <template #cell(prezzo_cadauno)="data">
            <div class="d-flex align-items-center">
              <span>{{ data.item.prezzo }} €</span>
            </div>
          </template>

          <!-- subtotale_riga -->
          <template #cell(subtotale_riga)="data">
            <div class="d-flex align-items-center">
              <span>{{ data.item.subtotale }} €</span>
            </div>
          </template>

          <!-- blocco_iva -->
          <template #cell(blocco_iva)="data">
            {{ data.item.iva+'%' }}
          </template>

          <!-- Column: Action -->
          <template #cell(elimina)="data">
            <div class="text-nowrap">
              <feather-icon
                    :id="`tabella1-riga-${data.item.id}-elimina`"
                    icon="XCircleIcon"
                    class="cursor-pointer mr-1"
                    size="21"
                    @click="cancellaRiga(userData.id_azienda,data.item.id)"
                />
                <b-tooltip
                    title="cancella"
                    class="cursor-pointer"
                    :target="`tabella1-riga-${data.item.id}-elimina`"
                />
            </div>
          </template>
          
        </b-table>
      </b-card>

      <b-row>
        <b-col xl="7" md="5">
          <b-card bg-variant="Default">
            <b-form>
              <b-form-group label="Numero CIG" label-for="cig">
                  <validation-provider
                      name="numero CIG"
                  >
                      <b-form-input
                      v-model="data.azienda.dati_fiscali.cig"
                      placeholder="in eventuale riferimento ad appalti pubblici, note o riferimenti esterni"
                      />
                  </validation-provider>
              </b-form-group>

            </b-form>
          </b-card>
        </b-col>
        <b-col xl="5" md="7">
          <b-card bg-variant="Default">
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Subtotale ordine</b-col>
              <b-col class="text-right">{{ data.totale.subtotale }} €</b-col>
            </b-row>
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Iva ordine</b-col>
              <b-col class="text-right">{{ data.totale.iva }} €</b-col>
            </b-row>
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Sconto applicato</b-col>
              <b-col class="text-right">{{ data.totale.sconto }} €</b-col>
            </b-row>
            <b-row class="font-weight-bolder">
              <b-col>Totale ordine</b-col>
              <b-col class="text-right">{{ data.totale.totalone }} €</b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/email.svg')"
                alt="anagrafica azienda"
                height="170"
              />
            </div>
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-top">
                <div class="meetup-day">
                  <span class="text-muted">codice cliente:</span><br />
                  <h5 class="mb-0">
                    {{ data.azienda.dati_fiscali.codice }}
                  </h5>
                </div>
                <div class="my-auto">
                  <h3 class="mb-25">
                    {{ data.azienda.dati_fiscali.rag_soc }}
                  </h3>
                  <b-card-text class="mb-25">
                    p.iva: {{ data.azienda.dati_fiscali.piva }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    sdi: {{ data.azienda.dati_fiscali.sdi }}
                  </b-card-text>
                </div>
              </div>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.dati_fiscali.indirizzo }}<br />
                    {{ data.azienda.dati_fiscali.cap }} - {{ data.azienda.dati_fiscali.localita }} ({{ data.azienda.dati_fiscali.provincia }}) - {{ data.azienda.dati_fiscali.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.dati_fiscali.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.dati_fiscali.email }}</h6>
                </b-media-body>
              </b-media>

            </b-card-body>

          </b-card>
        </b-col>
        <b-col md="6">
          <b-overlay variant="white" spinner-variant="primary" rounded="lg" :show="showoverlay" no-wrap></b-overlay>
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/sales.svg')"
                alt="spedizioni"
                height="170"
              />
            </div>
            <b-card-body>
              <b-tabs v-model="tabIndex" nav-class="mb-3">
                <b-tab
                  title="Indirizzi spedizioni salvati"
                  active
                  class="business-card"
                >
                  <b-card-title>Seleziona un'indirizzo per la consegna del tuo ordine</b-card-title>

                  <b-alert variant="success" align="center" show v-if="nessuna_indirizzo_di_spedizione===true">
                    <h5 class="alert-heading">nessun indirizzi di spedizione presente,<br />ti inviatiamo a inserirne uno prima di concludere l'ordine</h5>
                  </b-alert>

                  <div class="business-items">
                    <div
                      v-for="businessItem in data.azienda.spedizioni"
                      :key="businessItem.id"
                      class="business-item"
                    >
                      <div class="d-flex align-items-center justify-content-between">
                        <b-form-radio
                          v-model="Selected"
                          name="some-radios"
                          :value="businessItem.id"
                          >
                          <h5>{{ businessItem.rag_soc }}</h5>
                          {{ businessItem.indirizzo }}<br />
                          {{ businessItem.cap }} - {{ businessItem.localita }} ({{ businessItem.provincia }}) - {{ businessItem.paese }}
                        </b-form-radio>
                        <b-badge variant="secondary">
                          {{ businessItem.codice_spe }}
                        </b-badge>
                      </div>
                    </div>
                  </div>

                  <!-- button -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    size="lg"
                    class="text-uppercase"
                    v-if="nessuna_indirizzo_di_spedizione===false"
                    @click.prevent="SalvaOrdine(userData.id_azienda,userData.id_azienda_padre)"
                  >
                    Conferma ed Invia Ordine
                  </b-button>
                  

                </b-tab>
                <b-tab title="Nuovo Indirizzo">
                  <b-card-title>Inserisci i dati per un nuovo indirizzo di spedizione per il tuo ordine</b-card-title>

                  <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Codice spedizione" label-for="codice_spe">
                                    <validation-provider
                                        name="Codice spedizione"
                                    >
                                        <b-form-input
                                        v-model="campiform.codice_spe"
                                        placeholder="Codice spedizione"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="8">
                                <b-form-group label="Ragione Sociale *" label-for="rag_soc">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Ragione Sociale"
                                        rules="required"
                                    >
                                        <b-form-input
                                        v-model="campiform.rag_soc"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Ragione Sociale"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Indirizzo *" label-for="indirizzo">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Indirizzo"
                                        rules="required"
                                    >
                                        <b-form-input
                                        v-model="campiform.indirizzo"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Indirizzo"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Cap *" label-for="cap">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Cap"
                                        rules="required|digits:5"
                                    >
                                        <b-form-input
                                        v-model="campiform.cap"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Cap"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="8">
                                <b-form-group label="Località *" label-for="localita">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Località"
                                        rules="required"
                                    >
                                        <b-form-input
                                        v-model="campiform.localita"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Località"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="8">
                                <b-form-group label="Provincia *" label-for="provincia">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Provincia"
                                    rules="required"
                                    >
                                    <b-form-select
                                      v-model="campiform.provincia"
                                      :options="listaProvince"
                                      :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Nazione *" label-for="paese">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Nazione"
                                        rules="required"
                                    >
                                        <b-form-select
                                        v-model="campiform.paese"
                                        :options="options"
                                        :state="errors.length > 0 ? false:null"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6">
                                <b-form-group label="Telefono" label-for="telefono">
                                    <validation-provider
                                        name="Telefono"
                                    >
                                        <b-form-input
                                        v-model="campiform.telefono"
                                        placeholder="Telefono"
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group label="Email" label-for="email">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Email"
                                        rules="email"
                                    >
                                        <b-form-input
                                        v-model="campiform.email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Email"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                        <!-- submit button -->
                            <b-col md="12" class="text-center">
                            <b-button
                                variant="primary"
                                type="submit"
                                @click.prevent="validationForm(userData.id_azienda)"
                            >
                                Salva
                            </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                  </validation-observer>


                </b-tab>

              </b-tabs>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </section>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { BRow, BCol, BAlert, BLink, BCard, BCardText, BCardTitle, BImg, BButton, BCardBody, BMedia, BMediaAside, BMediaBody, 
BAvatar, BTab, BTabs, BFormRadio, BFormCheckbox, BInputGroup, BFormInput, BFormGroup, BForm, BFormSelect, BBadge, BJumbotron, 
BSpinner, BOverlay, BTable, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BTab,
    BTabs,
    BFormRadio,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BForm,
    BBadge,
    BJumbotron,
    BSpinner,
    BOverlay,
    BTable,
    BTooltip,
    
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'articolo', label: 'Articolo' },
        { key: 'qt', label: 'Quantità' },
        { key: 'um', label: 'UM' },
        { key: 'prezzo_cadauno', label: 'Prezzo cadauno' },
        { key: 'subtotale_riga', label: 'Subtotale' },
        { key: 'blocco_iva', label: 'Iva' },
        { key: 'sconto', label: 'Sconto' },
        { key: 'elimina', field: 'Elimina' },
      ],
      data: {
        carrello: { },
        totale: { },
        azienda: {
          dati_fiscali: { },
          spedizioni: { }
        }
      },
      carrello_vuoto: '',
      nessuna_indirizzo_di_spedizione: false,
      userData: {},
      listaProvince: [ ],
      Selected: '',
      tabIndex: 0,
      campiform: {
        codice_spe: '',
        rag_soc: '',
        paese: 'IT',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        telefono: '',
        email: '',
      },
      required,
      email,
      options: [
        { value: 'IT', text: 'IT' },
        { value: 'SM', text: 'SM' },
      ],
      showoverlay: false,
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    /*
    console.log('richiedi id => '+this.userData.id_azienda)
    console.log('richiedi id_azienda_padre => '+this.userData.id_azienda_padre)
    */

    this.$http.get('v2/articoli/infocarrellototale/'+this.userData.id_azienda)
      .then(response => {
        this.data = response.data

        if(response.data.carrello[0] === undefined){
          //carrello vuoto
          this.carrello_vuoto = true;
        } else {
          //almeno un articolo c'è => procediamo con l'ordine
          this.carrello_vuoto = false;

          if(response.data.azienda.spedizioni[0] === undefined){
            //non c'è nessuna indirizzo di spedizione salvato per la azienda => vai al form di inserimento
            this.nessuna_indirizzo_di_spedizione = true;
            this.cambioTabs(1);
          } else {
            this.SelezionePrimaSpedizioneNellaLista(response.data.azienda.spedizioni[0]['id'])
            this.nessuna_indirizzo_di_spedizione = false;
          }

          //estrazione lista province
          this.$http.get('v2/province/').then(response => {
            this.listaProvince = response.data.dati;
          })
        }

        console.log(response.data);

      })
  },
  methods: {
    cancellaRiga(id_azienda_passata,id_riga){
      console.log("click cancellaRiga ...... id -> "+id_riga);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la richiesta di cancellazione articolo dal carrello",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'SI, Cancella',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
          this.$http.get('v2/articoli/cancellasingolarigacarrello/'+id_azienda_passata+'/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)
                console.log("response.data.dati_risposta.id_riga_cancellata -> "+response.data.dati_risposta.id_riga_cancellata)

                if((response.data.statusCode===200) && (response.data.dati_risposta.id_riga_cancellata == id_riga)){
                //risposta positiva
                    this.$swal({
                        icon: 'success',
                        title: 'Cancellato!',
                        text: 'operazione effettuata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })

                    if(response.data.dati_risposta.articoli_carrello == 0){
                      //nessun articolo nel carrello !
                      this.carrello_vuoto = true;
                    }

                    //aggiorna riepilogo articoli nel carrelo (ricaricato da zero dal backend - non si mai!)
                    this.data.carrello = response.data.dati_risposta.carrello

                    //aggiorna riepilogo calcolo totali
                    this.data.totale = response.data.dati_risposta.totale

                    // Update count in cart items state
                    this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', response.data.dati_risposta.articoli_carrello)
                    
                } else {
                //risposta negativa (errore sul server)
                    this.$swal({
                        icon: 'error',
                        title: 'Si è verificato un errore',
                        text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - ERRORE DS-014',
                        customClass: {
                        confirmButton: 'btn btn-outline-primary',
                        },
                    })
                }
            }).catch(e => {
                console.log(e);
            })
      })
    },
    validationForm(id_azienda_passata) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //console.log('start invio form ... spriamo bene')
          //console.log('di azienda due, speriamo bene ... -> '+id_azienda_passata)

          //aggiungi ID_azienda al post in partenza
          this.campiform = Object.assign({}, this.campiform, {id_azienda: parseInt(id_azienda_passata)});

          //console.log(this.campiform)
          
          this.$http.post('v2/articoli/salvanuovaspedizione', {
            dati_form: this.campiform
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if(response.data.statusCode===200){
                //risposta positiva
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')

                //aggiorna lista spedizioni
                this.data.azienda.spedizioni = response.data.dati_risposta;
                //rimuovi eventuale messaggio di nessuna indirizzo di spedizione
                this.nessuna_indirizzo_di_spedizione = false;
                //seleziono l'indirizzo appena salvato nella lista
                this.SelezionePrimaSpedizioneNellaLista(response.data.dati_risposta[0]['id'])
                //visualizza tab della lista delle spedizioni
                this.cambioTabs(0);
              } else {
                //risposta negativa (errore sul server)
                this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
    cambioTabs(valore){
      //console.log("cambio tabs verso -> "+valore)
      this.tabIndex = valore;
    },
    SelezionePrimaSpedizioneNellaLista(valore_id_spedizione){
      //console.log("Selezione Prima Spedizione Nella Lista -> "+valore_id_spedizione)
      this.Selected = valore_id_spedizione
    },
    SalvaOrdine(id_azienda_passata,id_azienda_passata_padre){
      //console.log("salva ordine -------------------------\nid_azienda_passata -> "+id_azienda_passata+"\nid spedizione -> "+this.Selected+"\nid_utente -> "+this.userData.id);
      this.showoverlay = true;

      //console.log("_>->-> numero_cig da (data.azienda.dati_fiscali.cig) -> "+this.data.azienda.dati_fiscali.cig);


      this.$http.post('v2/articoli/salvaordinespe', {
          id_azienda: parseInt(id_azienda_passata),
          id_azienda_padre: parseInt(id_azienda_passata_padre), 
          id_spe: parseInt(this.Selected), 
          id_utente: parseInt(this.userData.id),
          numero_cig: this.data.azienda.dati_fiscali.cig
          }).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          this.showoverlay = false;

          if(response.data.statusCode===200){
            //risposta positiva

            if((response.data.dati_risposta.id_ordine_salvato > 0) && (response.data.dati_risposta.numero_ordine_salvato != '')){
              //redirect verso dettaglio ordine appena salvato
              this.$router.replace('/azienda/ordineinfo/'+response.data.dati_risposta.id_ordine_salvato+'/'+response.data.dati_risposta.numero_ordine_salvato)
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Ordine preso in carico',
                      text: 'Il tuo ordine è stato ricevuto correttamente e sarà evaso al più presto',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  // Update count in cart items state => 0
                  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
                })

            } else {
              //non c'è il numero ordine appena salvato (strano)
              //vai alla visualizzazione dei propri ultimi ordini 
              this.$router.replace('/azienda/ordini')
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Ordine preso in carico',
                      text: 'Il tuo ordine è stato ricevuto correttamente e sarà evaso al più presto',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  // Update count in cart items state => 0
                  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
                })
            }
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.error.descrizione,
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
            })
          }
        }).catch(e => {
          console.log(e);
        });

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>